import React from 'react';
import PropTypes from 'prop-types';
import errorImg  from './no-image.png';

export default class BigPhoto extends React.Component {
    state = {
        isLoading: false,
        error: false,
        src: null
    };
    componentDidMount() {
        this.loadImage(this.props.imgUrl);
    }

    loadImage = src => {
        let img = new Image();
        img.onload = () => {
            this.setState({ isLoading: true, error: false });
        };
        img.onerror = () => {
            this.setState({ isLoading: true, error: true });
        };
        img.src = src;
    };

    render() {
        const { isLoading } = this.state;
        const { imgUrl, imgAlt, imgClass } = this.props;
        const imgUrlView = !this.state.error ? imgUrl : errorImg;
        return !isLoading ? <p>Загружаю...</p> : <img src={ imgUrlView } alt={imgAlt} className={imgClass} />;
    }
}

BigPhoto.propTypes = {
    imgUrl: PropTypes.string.isRequired,
    imgAlt: PropTypes.string.isRequired
};