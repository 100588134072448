import React, {Component} from 'react';
import s from './item-list.module.css';
import Spiner from "./../spiner/spiner";
import AnimatedButtons from "../helpers/animated-side-buttons/animated-side-buttons";
import BigPhoto from "../helpers/img-loader/img-loader";

export default class ItemList extends Component {

    state = {
      itemList: null,
      loading: true,
    };

    componentDidMount(){
        this.updateList();
    }

    componentDidUpdate(prevProps){
        if (this.props.page !== prevProps.page){
            this.updateList(this.props.page);
            this.setState({
                loading: true
            });
        }
        if (this.props.countPages !== prevProps.countPages){
            this.renderButtons(this.props.countPages);
        }
    }



    onPageTrigger(plus) {
        const oldPage = this.props.page;
        let newPage;
        let change = true;
        if (plus) {
            newPage = oldPage + 1;
        } else {
            if (oldPage > 1){
                newPage = oldPage - 1;
            } else {
                newPage = 1;
                change = false;
            }
        }
        if (change) {
            this.setState({
                page: newPage,
                itemList: null
            }, () => {
                this.props.onPageTrigger(newPage);
                this.updateList(newPage);
            });
        }
    };

    updateList(page){
        this.props.getData(page)
            .then((itemList) => {
                this.setState({
                    itemList,
                    loading: false
                });
            });
    }


    renderItems(arr) {
        return arr.map((arr) => {
            const label = this.props.renderLabel(arr);
            return (
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => this.props.onItemSelected(arr.num)}
                    key={arr.num}>
                    <span className={s.boximg}>
                        <BigPhoto imgUrl={arr.img} imgAlt={arr.num} />
                    </span>
                    <span className={s.label}>{label}</span>
                </button>);
        });
    };

    renderButtons(count){
        const click_left = () => this.onPageTrigger(false);
        let click_right = () => this.onPageTrigger(true);
        if (this.props.page === count){
            click_right = null;
        }
        return  <div className={s.navbtns}>
                    <p onClick={click_left}><i className="fal fa-chevron-left"> </i> </p>
                    <p onClick={click_right}> <i className="fal fa-chevron-right"> </i> </p>
                </div>;
    }

    render(){
        const { itemList, loading} = this.state;
        const box_classname = s.list + ' btn-group-vertical';

        let view = '';
        if(!itemList){
            view = <Spiner/>
        } else {
            const items = this.renderItems(itemList);
            view = loading ? <Spiner/> : items;
        }
        let navButtons = <AnimatedButtons />;
        if (this.props.countPages){
            navButtons = this.renderButtons(this.props.countPages);
        }


        return (
            <div className={box_classname}>
                {navButtons}
                {view}
            </div>
        );
    }
};
