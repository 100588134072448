import React, {Component} from 'react';
import Row25 from "../helpers/row25";
import {StarshipsListSwapi} from "../item-list/item-lists/item-lists";
import {StarshipDetailsSwapi} from "../item-details/details/details";
import SwapiService from './../../service/swapiService';
import Pagination from "../pagination/pagination";

export default class StarshipsPage extends Component{

    swapi = new SwapiService();

    state = {
        selectedItem: 5,
        page: 1,
        countPages: null
    };

    onItemSelected = (num) => {
        this.setState({
            selectedItem: num,
        });
    };

    onPageTrigger = (page, count) => {
        this.setState({
            page
        });
    };

    onCountPage = (countPages) => {
        this.setState({
            countPages
        });
    };

    renderLabel = (item) => {
        return item.name;
    };

    render(){
        const starshipList = <StarshipsListSwapi page={this.state.page}
                                                 countPages={this.state.countPages}
                                                 onPageTrigger={this.onPageTrigger}
                                                 onItemSelected={this.onItemSelected}
                                                 renderLabel={this.renderLabel} />;

        const starshipDetails = <StarshipDetailsSwapi itemId={this.state.selectedItem}
                                                      detailLink={`/starships/${this.state.selectedItem}`}  />;

        const pagination = <Pagination onCountPage={this.onCountPage}
                                       onPageTrigger={this.onPageTrigger}
                                       getData={this.swapi.getCountStarships}
                                       activePage={this.state.page}
                                       url={`starships`} />;

        return (
            <React.Fragment>
                <Row25 left={starshipList} right={starshipDetails}/>
                {pagination}
            </React.Fragment>
        );
    }

}