import React from 'react';
import ItemDetails from './../item-details';
import DetailEl from "../detail-item/detail-el";
import WithSwapi from "../../helpers/hoc-helpers/with-swapi";

const PersonDetails = (props) => {
    return (
        <ItemDetails {...props} >
            <DetailEl label={'Родился'} field={'birthYear'}/>
            <DetailEl label={'Пол'} field={'gender'}/>
            <DetailEl label={'Вес'} field={'mass'}/>
            <DetailEl label={'Рост'} field={'height'}/>
            <DetailEl label={'Глаза'} field={'eyeColor'}/>
            <DetailEl label={'Волосы'} field={'hairColor'}/>
            <DetailEl label={'Цвет кожи'} field={'skinColor'}/>
        </ItemDetails>
    );
};
const StarshipDetails = (props) => {
    return (
        <ItemDetails {...props}>
            <DetailEl label={'Модель'} field={'model'}/>
            <DetailEl label={'Производитель'} field={'manufacturer'}/>
            <DetailEl label={'Стоимость'} field={'costInCredits'}/>
            <DetailEl label={'Длина'} field={'length'}/>
            <DetailEl label={'Команда'} field={'crew'}/>
            <DetailEl label={'Пассажиры'} field={'passengers'}/>
            <DetailEl label={'Грузоподъёмность'} field={'cargoCapacity'}/>
        </ItemDetails>
    );
};
const VehiclesDetails = (props) => {
    return (
        <ItemDetails {...props}>
            <DetailEl label={'Модель'} field={'model'}/>
            <DetailEl label={'Производитель'} field={'manufacturer'}/>
            <DetailEl label={'Стоимость'} field={'costInCredits'}/>
            <DetailEl label={'Длина'} field={'length'}/>
            <DetailEl label={'Команда'} field={'crew'}/>
            <DetailEl label={'Пассажиры'} field={'passengers'}/>
            <DetailEl label={'Грузоподъёмность'} field={'cargoCapacity'}/>
        </ItemDetails>
    );
};
const PlanetsDetails = (props) => {
    return (
        <ItemDetails {...props}>
            <DetailEl label={'Климат'} field={'climate'}/>
            <DetailEl label={'Диаметр'} field={'diameter'}/>
            <DetailEl label={'Гравитация'} field={'gravity'}/>
            <DetailEl label={'Период вращения'} field={'orbitalPeriod'}/>
            <DetailEl label={'Популяция'} field={'population'}/>
            <DetailEl label={'Вращение вокруг своей оси'} field={'rotationPeriod'}/>
            <DetailEl label={'Наличие воды (%)'} field={'surface_water'}/>
            <DetailEl label={'Ландшафт'} field={'terrain'}/>
        </ItemDetails>
    );
};
const SpeciesDetails = (props) => {
    return (
        <ItemDetails {...props}>
            <DetailEl label={'Средний рост (см.)'} field={'height'}/>
            <DetailEl label={'Средний срок жизни (лет)'} field={'lifeSpan'}/>
            <DetailEl label={'Классификация'} field={'classification'}/>
            <DetailEl label={'Обозначение'} field={'designation'}/>
            <DetailEl label={'Язык'} field={'language'}/>
        </ItemDetails>
    );
};

const mapMethodsToPropsPerson = (swapi) => {
    return {
        getData: swapi.getPerson,
        getImg:  swapi.getImgPerson
    }
};
const mapMethodsToPropsStarship = (swapi) => {
    return {
        getData: swapi.getStarship,
        getImg:  swapi.getImgStarship
    }
};
const mapMethodsToPropsVehicles = (swapi) => {
    return {
        getData: swapi.getVehicle,
        getImg:  swapi.getImgVehicle
    }
};
const mapMethodsToPropsPlanets = (swapi) => {
    return {
        getData: swapi.getPlanet,
        getImg:  swapi.getImgPlanet
    }
};
const mapMethodsToPropsSpecies = (swapi) => {
    return {
        getData: swapi.getSpecie,
        getImg:  swapi.getImgSpecie
    }
};

const PersonDetailsSwapi =  WithSwapi(PersonDetails, mapMethodsToPropsPerson);
const StarshipDetailsSwapi =  WithSwapi(StarshipDetails, mapMethodsToPropsStarship);
const VehicleDetailsSwapi =  WithSwapi(VehiclesDetails, mapMethodsToPropsVehicles);
const PlanetDetailsSwapi =  WithSwapi(PlanetsDetails, mapMethodsToPropsPlanets);
const SpecieDetailsSwapi =  WithSwapi(SpeciesDetails, mapMethodsToPropsSpecies);

export {
    PersonDetailsSwapi,
    StarshipDetailsSwapi,
    VehicleDetailsSwapi,
    PlanetDetailsSwapi,
    SpecieDetailsSwapi
};