import React from 'react';
import logo from './sw-logo.png';
import s from './header.module.css';
import {Link} from "react-router-dom";

const Header = () => {

    const navbar_classes = `navbar navbar-expand-lg navbar-dark bg-primary ${s.navbar}`;

    return (
        <nav className={navbar_classes} >
            <div className="container">

                <Link className="navbar-brand" to="/">
                    <img className={s.logo} src={logo} alt='Звездные войны' />
                </Link>

                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarColor01"
                        aria-controls="navbarColor01" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"> </span>
                </button>

                <div className="collapse navbar-collapse" id="navbarColor01">
                    <ul className="navbar-nav mr-auto">
                        <li className="nav-item">
                            <Link className="nav-link" to="/people">Персонажи</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/starships">Корабли</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/vehicles">Техника</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/planets">Планеты</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/species">Рассы</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/films">Эпизоды</Link>
                        </li>
                    </ul>
                    <i data-toggle="modal" data-target="#exampleModal" className={`fas fa-question-circle ${s.question}`}> </i>
                </div>
            </div>
        </nav>
    )
};

export default Header;