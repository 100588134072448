import React, {Component} from 'react';
import s from "./standart-list.module.css";
import Spiner from "./../spiner/spiner";
import InfoBox from "../helpers/standart-info-box/standart-info-box";
import {Link} from "react-router-dom";
import BigPhoto from "../helpers/img-loader/img-loader";


export default class StandartList extends Component{

    constructor() {
        super();

        this.state = {
            loading: true,
            itemsList: null,
        }
    }

    componentDidMount(){
        const {urlsArr, getDataByUrls} = this.props;
        getDataByUrls(urlsArr).then((arr) => {
            this.setState({
                loading: false,
                itemsList: arr
            });
        });
    }

    renderItems(arr) {
        if (arr.length){
            return arr.map((el) => {
                const {name, title, img, num, link} = el;
                const nameEl = name ? name : title;

                return (
                    <div key={num.toString()} className={s.box}>
                        <Link to={link}>
                            <div className={s.img_box}>
                                <BigPhoto imgUrl={img} imgAlt={num}/>
                            </div>
                            <p>
                                {nameEl}
                            </p>
                        </Link>
                    </div>
                );
            });
        } else {
            return <p>Информация не найдена</p>;
        }
    };

    render(){
        const items = !this.state.loading ? this.renderItems(this.state.itemsList) : <Spiner/>;

        return (
            <InfoBox title={this.props.titleBox} desc={this.props.descText} content={items} />
        )
    }
};