import React, {Component} from 'react';
import Row25 from "../helpers/row25";
import {PlanetsListSwapi} from "../item-list/item-lists/item-lists";
import {PlanetDetailsSwapi} from "../item-details/details/details";
import SwapiService from './../../service/swapiService';
import Pagination from "../pagination/pagination";

export default class PlanetsPage extends Component{

    swapi = new SwapiService();

    state = {
        selectedItem: 4,
        page: 1,
        countPages: null
    };

    onItemSelected = (num) => {
        this.setState({
            selectedItem: num,
        });
    };

    onPageTrigger = (page) => {
        this.setState({
            page
        });
    };

    onCountPage = (countPages) => {
        this.setState({
            countPages
        });
    };

    renderLabel = (item) => {
        return item.name;
    };

    render(){
        const planetsList = <PlanetsListSwapi page={this.state.page}
                                               countPages={this.state.countPages}
                                               onPageTrigger={this.onPageTrigger}
                                               onItemSelected={this.onItemSelected}
                                               renderLabel={this.renderLabel} />;

        const planetDetails = <PlanetDetailsSwapi itemId={this.state.selectedItem}
                                                      detailLink={`/planets/${this.state.selectedItem}`} />;

        const pagination = <Pagination onCountPage={this.onCountPage}
                                       onPageTrigger={this.onPageTrigger}
                                       getData={this.swapi.getCountPlanets}
                                       activePage={this.state.page}
                                       url={`planets`} />;
        return (
            <React.Fragment>
                <Row25 left={planetsList} right={planetDetails}/>
                {pagination}
            </React.Fragment>
        );
    }

}