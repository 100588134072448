import React from 'react';

//В скобках как бы значение по умолчанию
//Если наш консьюмер не найдет никакого провайдера - то он обратится сюда
//Но в нашем приложении всегда тут будет какое то значение, поэтому сюда мы ничего не передаем

//Мы просто переименовываем Provider и Consumer, для того что бы их имена лучше отоборажали суть
const {
    Provider:SwapiProvider,
    Consumer:SwapiConsumer
} = React.createContext();

export {
    SwapiProvider,
    SwapiConsumer
};