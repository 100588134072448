import React from 'react';
import s from './row.module.css';

const Row25 = ({left, right}) => {
    return (
        <div className={s.inbox}>
            {left}
            {right}
        </div>
    );
};

export default Row25;