import React,{Component} from 'react';
import s from './films.module.css';
import BigPhoto from "./../../helpers/img-loader/img-loader";
import SwapiService from './../../../service/swapiService';
import Row11 from './../../helpers/row11';
import Spiner from "./../../spiner/spiner";
import InfoBox from "../../helpers/standart-info-box/standart-info-box";
import StandartList from "../../standart-list/standart-list";

export default class Films extends Component{

    swapi = new SwapiService();

    constructor(){
        super();

        this.state = {
            item:{},
            loading: true
        };

        this.onItemLoaded = (item) => {
            this.setState({
                item,
                loading: false,
            });
        };

        this.onItemError = () => {
            this.setState({
                loading: false
            });
        };
    }

    componentDidMount(){
        this.updateItem();
    }

    updateItem = () => {
        this.swapi.getFilm(this.props.match.params.id)
            .then(this.onItemLoaded)
            .catch(() => {console.error('Ошибка catch')});
    };

    render () {
        const {loading, item} = this.state;
        const {getPeopleByUrls,
                getAllStarshipsByUrls,
                getAllVehiclesByUrls,
                getPlanetsByUrls,
                getAllSpeciesByUrls,
            } = this.swapi;

        const view = loading ? '' : <OneObjView getPeopleByUrls={getPeopleByUrls}
                                                getAllStarshipsByUrls={getAllStarshipsByUrls}
                                                getAllVehiclesByUrls={getAllVehiclesByUrls}
                                                getPlanetsByUrls={getPlanetsByUrls}
                                                getAllSpeciesByUrls={getAllSpeciesByUrls}
                                                item={item}
        />;

        const load = loading ? <Spiner /> : '';

        return (
            <div className={s.mainPageContainer}>
                {view}
                {load}
            </div>
        );
    }
};



const OneObjView = ({item,
                    getPeopleByUrls,
                    getAllStarshipsByUrls,
                    getAllVehiclesByUrls,
                    getAllSpeciesByUrls,
                    getPlanetsByUrls}) => {

    const {model, name, characters, starships, vehicles, planets, species, img} = item;

    const peopleCom = <StandartList titleBox={'Персонажи'} urlsArr={characters} getDataByUrls={getPeopleByUrls} />;
    const starshipsCom = <StandartList titleBox={'Корабли'} urlsArr={starships} getDataByUrls={getAllStarshipsByUrls} />;
    const vehicleslCom = <StandartList titleBox={'Техника'} urlsArr={vehicles} getDataByUrls={getAllVehiclesByUrls} />;
    const planetslCom = <StandartList titleBox={'Планеты'} urlsArr={planets} getDataByUrls={getPlanetsByUrls} />;
    const spacieslCom = <StandartList titleBox={'Рассы'} urlsArr={species} getDataByUrls={getAllSpeciesByUrls} />;

    const viewDetails =  <ViewDetails item={item} />;


    const leftContent = <React.Fragment>
                            {viewDetails}
                            {starshipsCom}
                            {spacieslCom}
                        </React.Fragment>;
    const rightContent = <React.Fragment>
                            {peopleCom}
                            {vehicleslCom}
                            {planetslCom}
                        </React.Fragment>;

    const style_back = {
        backgroundImage: `url(${img})`
    };
    return (
        <React.Fragment>
            <div className={s.box}>
                <div className={s.back} style={style_back}> </div>
                <h1>{name}</h1>
                <p className={s.model}>{model}</p>
                <BigPhoto imgUrl={img} imgAlt={name}/>
            </div>
            <Row11 left={leftContent} right={rightContent} />

        </React.Fragment>
    );
};


const ViewDetails = ({item}) => {
    const {
        title, director, producer, release_date, opening_crawl
    } = item;

    const titlex = `Эпизод ${title}`;
    const desc = `Товарищ Капитан! Вот всё, что нам удалось найти по вашему запросу.`;
    const content = <div>
                        <p><span>Режисер: </span> {director}</p>
                        <p><span>Продюсер: </span> {producer}</p>
                        <p><span>Дата релиза: </span> {release_date}</p>
                        <br />
                        <p><span>Предисловие: </span> {opening_crawl}</p>
                    </div>;

    return (
        <InfoBox title={titlex} desc={desc} content={content} />
    );
};

