import React, {Component} from 'react';
import s from './item-details.module.css';
import {Link} from "react-router-dom";
import DetailLoader from "./detail-loader/detail-loader";
import BigPhoto from "../helpers/img-loader/img-loader";

export default class ItemDetails extends Component{

    state = {
      item: null,
      loading: true
    };

    componentDidMount(){
        this.updateItem();
    }

    componentDidUpdate(prevProps){
        if (this.props.itemId !== prevProps.itemId){
            this.updateItem();
            this.setState({
                loading: true
            });
        }
    }

    updateItem(){
        const { itemId, getData } = this.props;
        if(!itemId){
            return;
        }
        getData(itemId)
            .then((item) => this.setState({
                item,
                loading: false
            }));
    }


    render(){
        const view = !this.state.loading ? <DetailView item={this.state.item}
                                                       getImgData={this.props.getImg}
                                                       childrens={this.props.children}
                                                       detailLink={this.props.detailLink}
                                                       changeGround={this.changeGround}
                                            /> : <DetailLoader/>;
        return (
            <div className={s.details}>
                {view}
            </div>
        );
    }
};


const DetailView = ({item, getImgData, childrens, detailLink}) => {

    const {name, num} = item;
    const img_src = getImgData(num);

    return (
        <React.Fragment>

            <div className={s.imgbox} >
                <Link to={detailLink} >
                    <BigPhoto imgUrl={img_src} imgAlt={name} />
                </Link>
            </div>

            <div className={s.desc}>
                <p className={s.header}>{name}</p>
                {
                    React.Children.map(childrens, (child) => {
                        return React.cloneElement(child,{ item });
                    })
                }
                <Link className={`btn btn-primary ${s.link}`} to={detailLink} > <i className="fal fa-info-circle"> </i> Подробнее </Link>
            </div>
        </React.Fragment>
    );
};