import React from 'react';
import { SwapiConsumer } from './../../../service/swapi-service-context/';

const WithSwapi = (Wrapped, mapMethods) => {
    return (props) => {
        return (<SwapiConsumer>
            {
            (swapi) => {
                const swapiProps = mapMethods(swapi);
                return (
                    <Wrapped {...props} {...swapiProps} />
                );
            }
        }
    </SwapiConsumer>)
    }
};

export default WithSwapi;