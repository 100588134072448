import React,{Component} from 'react';
import s from './pagination.module.css';

export default class Pagination extends Component {

    constructor(props){
      super(props);

      this.state = {
          count: 0,
          loading: true
      }

    };

    componentDidMount(){
        const { getData, onCountPage } = this.props;
        getData().then((count) => {
            onCountPage(count);
            this.setState({
                count,
                loading: false
            });
        });
    }


    renderItems = (count) => {
        let items = [];
        const {activePage} = this.props;
        for (let i = 1; i < count + 1; i++){
            const activeClass = i === activePage ? s.active : ``;
            const el =  <span key={`itempag${i}`}
                              onClick={() => this.props.onPageTrigger(i)}
                              className={s.pag_elem + ` ` +  activeClass}>
                              {i}
                        </span>;
            items = [...items, el];
        }
        return items
    };

    render(){
        let items = ``;
        if (!this.state.loading){
            items = this.renderItems(this.state.count);
        }
        return (
            <div className={s.pagination}>
                {items}
            </div>
        )
    }

};

