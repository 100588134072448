import React,{Component} from 'react';
import s from './person.module.css';
import BigPhoto from "./../../helpers/img-loader/img-loader";
import SwapiService from './../../../service/swapiService';
import Row11 from './../../helpers/row11';
import Spiner from "./../../spiner/spiner";
import EpisodesList from "./../../episode-list/episode-list";
import InfoBox from "../../helpers/standart-info-box/standart-info-box";
import StandartList from "../../standart-list/standart-list";

export default class Person extends Component{

    swapi = new SwapiService();

    constructor(){
        super();

        this.state = {
            item:{},
            loading: true
        };

        this.onItemLoaded = (item) => {
            this.setState({
                item,
                loading: false,
            });
        };

        this.onItemError = () => {
            this.setState({
                loading: false
            });
        };
    }

    componentDidMount(){
        this.updateItem();
    }

    updateItem = () => {
        this.swapi.getPerson(this.props.match.params.id)
            .then(this.onItemLoaded)
            .catch(() => {console.error('Ошибка catch')});
    };

    render () {
        const {loading, item} = this.state;
        const {getAllFilmsByUrls, getAllStarshipsByUrls, getAllVehiclesByUrls} = this.swapi;

        const view = loading ? '' : <OneObjView getAllFilmsByUrls={getAllFilmsByUrls}
                                                getAllStarshipsByUrls={getAllStarshipsByUrls}
                                                getAllVehiclesByUrls={getAllVehiclesByUrls}
                                                item={item}
        />;

        const load = loading ? <Spiner /> : '';

        return (
            <React.Fragment >
                {view}
                {load}
            </React.Fragment>
        );
    }
};



const OneObjView = ({item, getAllFilmsByUrls, getAllStarshipsByUrls, getAllVehiclesByUrls}) => {

    const {model, name, films, img, starships, vehicles} = item;

    const episodes = <EpisodesList getAllFilmsByUrls={getAllFilmsByUrls} films={films} />;
    const starshipsCom = <StandartList titleBox={'Пилотирует корабли'} urlsArr={starships} getDataByUrls={getAllStarshipsByUrls} />;
    const vehiclesCom = <StandartList titleBox={'Управляет техникой'} urlsArr={vehicles} getDataByUrls={getAllVehiclesByUrls} />;


    const rightContent = <React.Fragment>
                            {starshipsCom}
                            {vehiclesCom}
                         </React.Fragment>;

    const viewDetails =  <ViewDetails item={item} />;

    const style_back = {
        backgroundImage: `url(${img}`
    };

    return (
        <React.Fragment>
            <div className={s.box}>
                <div className={s.back} style={style_back}> </div>
                <h1>{name}</h1>
                <p className={s.model}>{model}</p>
                <BigPhoto imgUrl={img} imgAlt={name}/>
            </div>
            <Row11 left={viewDetails} right={rightContent} />
            {episodes}
        </React.Fragment>
    );
};


const ViewDetails = ({item}) => {
    const {
        name, birthYear, gender, eyeColor, height, mass, skinColor, hairColor
    } = item;

    const title = `Сводка о ${name}`;
    const desc = `Товарищ Капитан! Вот всё, что нам удалось найти по вашему запросу.`;
    const content = <div>
                        <p><span>Дата рождения: </span> {birthYear}</p>
                        <p><span>Пол: </span> {gender}</p>
                        <br />

                        <p><span>Рост: </span> {height}</p>
                        <p><span>Вес: </span> {mass}</p>
                        <p><span>Цвет глаз: </span> {eyeColor}</p>
                        <p><span>Цвет кожи: </span> {skinColor}</p>
                        <p><span>Цвет волос: </span> {hairColor}</p>
                    </div>;

    return (
        <InfoBox title={title} desc={desc} content={content} />
    );
};

