import React, {Component} from 'react';
import Row25 from "../helpers/row25";
import {VehiclesListSwapi} from "../item-list/item-lists/item-lists";
import {VehicleDetailsSwapi} from "../item-details/details/details";
import SwapiService from './../../service/swapiService';
import Pagination from "../pagination/pagination";

export default class VehiclesPage extends Component{

    swapi = new SwapiService();

    state = {
        selectedItem: 6,
        page: 1,
        countPages: null
    };

    onItemSelected = (num) => {
        this.setState({
            selectedItem: num,
        });
    };

    onPageTrigger = (page, count) => {
        this.setState({
            page
        });
    };

    onCountPage = (countPages) => {
        this.setState({
            countPages
        });
    };

    renderLabel = (item) => {
        return item.name;
    };

    render(){
        const vehicleList = <VehiclesListSwapi page={this.state.page}
                                               countPages={this.state.countPages}
                                               onPageTrigger={this.onPageTrigger}
                                               onItemSelected={this.onItemSelected}
                                               renderLabel={this.renderLabel} />;

        const vehicleDetails = <VehicleDetailsSwapi itemId={this.state.selectedItem}
                                                      detailLink={`/vehicles/${this.state.selectedItem}`} />;

        const pagination = <Pagination onCountPage={this.onCountPage}
                                       onPageTrigger={this.onPageTrigger}
                                       getData={this.swapi.getCountVehicles}
                                       activePage={this.state.page}
                                       url={`vehicles`} />;
        return (
            <React.Fragment>
                <Row25 left={vehicleList} right={vehicleDetails}/>
                {pagination}
            </React.Fragment>
        );
    }

}