import React from 'react';
import s from "./index-page.module.css";
import imgPeople from "./imgs/people.jpg";
import imgStarships from "./imgs/starships.jpg";
import imgVeinchles from "./imgs/veinchles.jpeg";
import imgPlanets from "./imgs/planets.png";
import imgSpecies from "./imgs/species.jpg";
import imgFilms from "./imgs/films.jpg";
import {Link} from "react-router-dom";

const IndexPage = () => {
    return (
        <div className={s.box}>
            <h1 className={s.zag}>
                <span>Звездные Воины</span>
                <br />
                Визуальный гид по вселенной
            </h1>
            <div className={s.item}>
                <Link to={`/people`}>
                    <img alt={`Персонажи`} src={imgPeople} />
                </Link>
                <p>Персонажи</p>
            </div>
            <div className={s.item}>
                <Link to={`/starships`}>
                    <img alt={`Корабли`} src={imgStarships} />
                </Link>
                <p>Корабли</p>
            </div>
            <div className={s.item}>
                <Link to={`/vehicles`}>
                    <img alt={`Техника`} src={imgVeinchles} />
                </Link>
                <p>Техника</p>
            </div>
            <div className={s.item}>
                <Link to={`/planets`}>
                    <img alt={`Планеты`} src={imgPlanets} />
                </Link>
                <p>Планеты</p>
            </div>
            <div className={s.item}>
                <Link to={`/species`}>
                    <img alt={`Рассы`} src={imgSpecies} />
                </Link>
                <p>Рассы</p>
            </div>
            <div className={s.item}>
                <Link to={`/films`}>
                    <img alt={`Эпизоды`} src={imgFilms} />
                </Link>
                <p>Эпизоды</p>
            </div>
        </div>
    );
};

export default IndexPage;