import React,{Component} from 'react';
import s from './planet.module.css';
import BigPhoto from "./../../helpers/img-loader/img-loader";
import SwapiService from './../../../service/swapiService';
import Row11 from './../../helpers/row11';
import Spiner from "./../../spiner/spiner";
import EpisodesList from "./../../episode-list/episode-list";
import InfoBox from "../../helpers/standart-info-box/standart-info-box";
import StandartList from "../../standart-list/standart-list";

export default class Planet extends Component{

    swapi = new SwapiService();

    constructor(){
        super();

        this.state = {
            item:{},
            loading: true
        };

        this.onItemLoaded = (item) => {
            this.setState({
                item,
                loading: false,
            });
        };

        this.onItemError = () => {
            this.setState({
                loading: false
            });
        };
    }

    componentDidMount(){
        this.updateItem();
    }

    updateItem = () => {
        this.swapi.getPlanet(this.props.match.params.id)
            .then(this.onItemLoaded)
            .catch(() => {console.error('Ошибка catch')});
    };

    render () {
        const {loading, item} = this.state;
        const {getAllFilmsByUrls, getPeopleByUrls} = this.swapi;

        const view = loading ? '' : <OneObjView getPeopleByUrls={getPeopleByUrls}
                                                getAllFilmsByUrls={getAllFilmsByUrls}
                                                item={item}
        />;

        const load = loading ? <Spiner /> : '';

        return (
            <React.Fragment >
                {view}
                {load}
            </React.Fragment>
        );
    }
};



const OneObjView = ({item, getAllFilmsByUrls, getPeopleByUrls}) => {

    const {model, name, films, residents, img} = item;

    const episodes = <EpisodesList getAllFilmsByUrls={getAllFilmsByUrls} films={films} />;
    const residentsCom = <StandartList titleBox={'Резиденты'} urlsArr={residents} getDataByUrls={getPeopleByUrls} />;
    const rightContent = <React.Fragment>
                            {residentsCom}
                         </React.Fragment>;

    const viewDetails =  <ViewDetails item={item} />;

    const style_back = {
        backgroundImage: `url(${img})`
    };

    return (
        <React.Fragment>
            <div className={s.box}>
                <div className={s.back} style={style_back}> </div>
                <h1>{name}</h1>
                <p className={s.model}>{model}</p>
                <BigPhoto imgUrl={img} imgAlt={name}/>
            </div>
            <Row11 left={viewDetails} right={rightContent} />
            {episodes}
        </React.Fragment>
    );
};


const ViewDetails = ({item}) => {
    const {
        name,
        climate,
        terrain,
        diameter,
        gravity,
        orbitalPeriod,
        rotationPeriod,
        population,
        surface_water
    } = item;

    const title = `Сводка о ${name}`;
    const desc = `Товарищ Капитан! Вот всё, что нам удалось про эту планету`;
    const content = <div>
                        <p><span>Климат: </span> {climate}</p>
                        <p><span>Ландшафт: </span> {terrain}</p>
                        <p><span>Диаметр: </span> {diameter} км.</p>
                        <br />
                        <p><span>Гравитация: </span> {gravity}</p>
                        <p><span>Период вращения вокруг звезды: </span> {orbitalPeriod} земных дней</p>
                        <p><span>Вращение вокруг своей оси: </span> {rotationPeriod} земных дней</p>
                        <p><span>Популяция: </span> {population} особей</p>
                        <p><span>Наличие воды: </span> {surface_water} %</p>
                    </div>;
    return (
        <InfoBox title={title} desc={desc} content={content} />
    );
};

