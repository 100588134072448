export default class SwapiService {

    _apiBase = 'https://swapi.co/api';
    _imgBase = 'https://starwars-visualguide.com/assets/img';

    getResource = async (url) => {
        const res = await fetch(`${this._apiBase}${url}`);
        if (!res.ok) {
            throw new Error(`Нельзя загрузить ${url}` +
                `, received ${res.status}`)
        }
        return await res.json();
    };


    getAllPeople = async (page) => {
        const pageStr = page ? `?page=${page}` : ``;
        const res = await this.getResource(`/people/${pageStr}`);
        return res.results.map(this._transformPerson);
    };
    getCountPeople = async () => {
        const res = await this.getResource(`/people/`);
        return  Math.ceil(res.count/10);
    };
    getPerson = async (id) => {
        const person = await this.getResource(`/people/${id}/`);
        return this._transformPerson(person);
    };
    getPeopleByUrls = async (arrUrl) => {
        const ids = this._giveMeIds(arrUrl);
        return await this.getPeopleByIds(ids);
    };
    getPeopleByIds = async (ids) => {
        return await this._getElementsByIds(ids, this.getPerson);
    };


    getAllFilms = async () => {
        const res = await this.getResource(`/films/`);
        return res.results.map(this._transformFilm);
    };
    getFilm = async (id) => {
        const res = await this.getResource(`/films/${id}/`);
        return this._transformFilm(res);
    };
    getAllFilmsByUrls = async (arrFilmsUrl) => {
        const filmsIds = this._giveMeIds(arrFilmsUrl);
        return await this.getAllFilmsByIds(filmsIds);
    };
    getAllFilmsByIds = async (ids) => {
        return await this._getElementsByIds(ids, this.getFilm);
    };


    getAllStarships = async (page) => {
        const pageStr = page ? `?page=${page}` : ``;
        const res = await this.getResource(`/starships/${pageStr}`);
        return res.results.map(this._transformStarship);
    };
    getCountStarships = async () => {
        const res = await this.getResource(`/starships/`);
        return  Math.ceil(res.count/10);
    };
    getStarship = async (id) => {
        const starship = await this.getResource(`/starships/${id}/`);
        return this._transformStarship(starship);
    };
    getAllStarshipsByUrls = async (arrUrl) => {
        const starshipsIds = this._giveMeIds(arrUrl);
        return await this.getAllStarshipsByIds(starshipsIds);
    };
    getAllStarshipsByIds = async (ids) => {
        return await this._getElementsByIds(ids, this.getStarship);
    };


    getAllSpecies = async (page) => {
        const pageStr = page ? `?page=${page}` : ``;
        const res = await this.getResource(`/species/${pageStr}`);
        return res.results.map(this._transformSpecie);
    };
    getCountSpecies = async () => {
        const res = await this.getResource(`/species/`);
        return  Math.ceil(res.count/10);
    };
    getSpecie = async (id) => {
        const specie = await this.getResource(`/species/${id}/`);
        return this._transformSpecie(specie);
    };
    getAllSpeciesByUrls = async (arrUrl) => {
        const speciesIds = this._giveMeIds(arrUrl);
        return await this.getAllSpeciesByIds(speciesIds);
    };
    getAllSpeciesByIds = async (ids) => {
        return await this._getElementsByIds(ids, this.getSpecie);
    };


    getAllVehicles = async (page) => {
        const pageStr = page ? `?page=${page}` : ``;
        const res = await this.getResource(`/vehicles/${pageStr}`);
        return res.results.map(this._transformVehicle);
    };
    getCountVehicles = async () => {
        const res = await this.getResource(`/vehicles/`);
        return  Math.ceil(res.count/10);
    };
    getVehicle = async (id) => {
        const vehicle = await this.getResource(`/vehicles/${id}/`);
        return this._transformVehicle(vehicle);
    };
    getAllVehiclesByUrls = async (arrUrl) => {
        const vehiclesIds = this._giveMeIds(arrUrl);
        return await this.getAllVehiclesByIds(vehiclesIds);
    };
    getAllVehiclesByIds = async (ids) => {
        return await this._getElementsByIds(ids, this.getVehicle);
    };

    getPlanets = async (page) => {
        const pageStr = page ? `?page=${page}` : ``;
        const res = await this.getResource(`/planets/${pageStr}`);
        return res.results.map(this._transformPlanet);
    };
    getCountPlanets = async () => {
        const res = await this.getResource(`/planets/`);
        return  Math.ceil(res.count/10);
    };
    getPlanet = async (id) => {
        const planet = await this.getResource(`/planets/${id}/`);
        return this._transformPlanet(planet);
    };
    getPlanetsByUrls = async (arrUrl) => {
        const ids = this._giveMeIds(arrUrl);
        return await this.getPlanetsByIds(ids);
    };
    getPlanetsByIds = async (ids) => {
        return await this._getElementsByIds(ids, this.getPlanet);
    };


    _extractID(item){
        const idRegExp = /\/([0-9]*)\/$/;
        return item.url.match(idRegExp)[1];
    };
    _extractIDByUrl(url){
        const idRegExp = /\/([0-9]*)\/$/;
        return url.match(idRegExp)[1];
    };
    _getElementsByIds = async (ids, getData) => {
        let arr = [];
        for (const id of ids){
            const el = await getData(id);
            arr = [...arr, el];
        }
        return arr;
    };
    _giveMeIds = (arr) => {
        let elementsIds = [];
        arr.forEach((url) => {
            const idFromUrl = this._extractIDByUrl(url);
            elementsIds.push(idFromUrl);
        });
        return elementsIds;
    };


    _transformPlanet = (planet) => {
        const id = this._extractID(planet);
        return {
            num: id,
            name: planet.name,
            climate: planet.climate,
            population: planet.population,
            rotationPeriod: planet.rotation_period,
            orbitalPeriod: planet.orbital_period,
            diameter: planet.diameter,
            films: planet.films,
            gravity: planet.gravity,
            residents: planet.residents,
            surface_water: planet.surface_water,
            terrain: planet.terrain,
            img: this.getImgPlanet(id),
            link: `/planets/${id}`
        };
    };
    _transformPerson = (person) => {
        const id = this._extractID(person);
        return {
            num: id,
            name: person.name,
            gender: person.gender,
            birthYear: person.birth_year,
            eyeColor: person.eye_color,
            height: person.height,
            mass: person.mass,
            skinColor: person.skin_color,
            hairColor: person.hair_color,
            films: person.films,
            starships: person.starships,
            vehicles: person.vehicles,
            img: this.getImgPerson(id),
            link: `/people/${id}`
        };
    };
    _transformFilm = (film) => {
        const id = this._extractID(film);
        return {
            num: id,
            director: film.director,
            producer: film.producer,
            episode_id: film.episode_id,
            title: film.title,
            release_date: film.release_date,
            opening_crawl: film.opening_crawl,
            planets: film.planets,
            species: film.species,
            starships: film.starships,
            vehicles: film.vehicles,
            characters: film.characters,
            img: this.getImgFilm(id),
            link: `/films/${id}`
        };
    };
    _transformStarship = (starship) => {
        const id = this._extractID(starship);
        return {
            num: id,
            name: starship.name,
            classSt: starship.starship_class,
            model: starship.model,
            manufacturer: starship.manufacturer,
            costInCredits: starship.cost_in_credits,
            length: starship.length,
            crew: starship.crew,
            passengers: starship.passengers,
            cargoCapacity: starship.cargo_capacity,
            maxSpeedAtmo: starship.max_atmosphering_speed,
            coreClass: starship.hyperdrive_rating,
            consumables: starship.consumables,
            films: starship.films,
            pilots: starship.pilots,
            img: this.getImgStarship(id),
            link: `/starships/${id}`
        };
    };
    _transformVehicle = (vehicle) => {
        const id = this._extractID(vehicle);
        return {
            num: id,
            name: vehicle.name,
            model: vehicle.model,
            manufacturer: vehicle.manufacturer,
            costInCredits: vehicle.cost_in_credits,
            length: vehicle.length,
            crew: vehicle.crew,
            passengers: vehicle.passengers,
            cargoCapacity: vehicle.cargo_capacity,
            maxSpeedAtmo: vehicle.max_atmosphering_speed,
            films: vehicle.films,
            pilots: vehicle.pilots,
            img: this.getImgVehicle(id),
            link: `/vehicle/${id}`
        };
    };
    _transformSpecie = (specie) => {
        const id = this._extractID(specie);
        return {
            num: id,
            name: specie.name,
            height: specie.average_height,
            lifeSpan: specie.average_lifespan,
            classification: specie.classification,
            designation: specie.designation,
            eye_colors: specie.eye_colors,
            hair_colors: specie.hair_colors,
            homeworld_url: specie.homeworld,
            language: specie.language,
            people: specie.people,
            films: specie.films,
            skin_colors: specie.skin_colors,
            img: this.getImgSpecie(id),
            link: `/species/${id}`
        };
    };


    getImgPerson = (num) => {
        return `${this._imgBase}/characters/${num}.jpg`;
    };
    getImgStarship = (num) => {
        return `${this._imgBase}/starships/${num}.jpg`;
    };
    getImgPlanet = (num) => {
        return `${this._imgBase}/planets/${num}.jpg`;
    };
    getImgFilm = (num) => {
        return `${this._imgBase}/films/${num}.jpg`;
    };
    getImgVehicle = (num) => {
        return `${this._imgBase}/vehicles/${num}.jpg`;
    };
    getImgSpecie = (num) => {
        return `${this._imgBase}/species/${num}.jpg`;
    };


}