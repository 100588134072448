import React from 'react';
import s from './modal.module.css';

const Modal = () => {
    return (
        <div className="modal fade bd-example-modal-lg" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className={`modal-content ${s.content}`}>
                    <div className={`modal-body ${s.modal_body}`}>
                        <h3 className={s.title}>Описание проекта</h3>
                        <p>Приветствуем, Вы в визуальном гиде
                            по вселенной Star Wars.<br /> UI (User interface)
                            проекта функцианирует на библеотеке
                            <a title={'Библиотека от FaceBook React JS'} href="https://reactjs.org/" target='_blank' rel="noopener noreferrer" >
                                React JS
                            </a>.
                            Серверная часть любезно предоставляется
                            <a title={'API SWAPI'} href="https://swapi.co/" target='_blank' rel="noopener noreferrer" >
                                SWAPI
                            </a>.
                            Разработан агенством интернет-маркетина <a title={'Создание и продвижение web-проектов'} href="http://run-seo.ru/" target='_blank' rel="noopener noreferrer" >Run SEO</a>.</p>

                        <h3 className={s.title}>Почему ничего не грузится?</h3>
                        <p>Как мы уже сообщали все данные предоставляются по бесплатному API
                            сервисом SWAPI, который в свою очередь создан такими же простыми
                            разработчиками как и мы, без парня с бабками из Lucasfilm,
                            поэтому его сила не безгранична и порой он перегружен, обычно
                            это случается вечером, если такая неприятность произошла,
                            как мы заметили при  разработке, нужно подождать 1-2 минуты и сервис становится доступен вновь,
                            рекомендуем подождать и обновить страницу. Спасибо за понимание!</p>

                        <h3 className={s.title}>Фанатам Звездных Воин</h3>
                        <p>Гид создан в шутку и ради шутки и не содержит абсолютно всех данных о Вселенной Star Wars.
                        Да прибудет с вами море спокойствия и всепрощения.</p>
                        <h3 className={s.title}>По оставшимся вопросам</h3>
                        <p>Мы с радостью ответим на любые ваши вопросы в <a href="https://vk.com/runseo">нашей группе</a> ВКонтакте,
                            или Вы можете воспользоваться <a target='_blank' rel="noopener noreferrer" href="http://run-seo.ru/site/contacts#box_big_form">формой обратной связи</a> на нашем сайте.</p>
                        <button type="button" className={`btn btn-secondary ${s.button}`} data-dismiss="modal">Закрыть это окно</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;