import React, {Component} from 'react';
import SwapiService from './../../service/swapiService';
import s from './films-page.module.css';
import {Link} from "react-router-dom";
import Spiner from "../spiner/spiner";
import BigPhoto from "../helpers/img-loader/img-loader";

export default class FilmsPage extends Component{

    swapi = new SwapiService();

    state = {
        itemsList: null,
        loading: true
    };

    componentDidMount(){
        this.swapi.getAllFilms().then((arr) => {
            this.setState({
                loading: false,
                itemsList: arr
            });
        });
    }

    renderItems = (arr) => {
        return arr.map((el) => {
            const {num, title, link, img, release_date} = el;
            return (
                <div key={num.toString()} className={s.prewbox}>
                    <Link to={link}>
                        <BigPhoto imgUrl={img} imgAlt={title} />
                        <p>{title}</p>
                        <span>({release_date})</span>
                    </Link>
                </div>
            );
        });
    };

    render(){
        const items = !this.state.loading ? this.renderItems(this.state.itemsList) : <Spiner/>;
        return (
            <div className={s.filmbox}>
                <h1>Star Wars | Эпизоды</h1>
                {items}
            </div>
        );
    }

}