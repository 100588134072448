import React, {Component} from 'react';
import Row25 from "../helpers/row25";
import {PersonListSwapi} from "../item-list/item-lists";
import {PersonDetailsSwapi} from "../item-details/details/details";
import SwapiService from './../../service/swapiService';
import Pagination from "../pagination/pagination";

export default class PersonPage extends Component{

    swapi = new SwapiService();

    state = {
        selectedItem: 2,
        page: 1,
        countPages: null
    };

    onItemSelected = (num) => {
        this.setState({
            selectedItem: num,
        });
    };

    onPageTrigger = (page, count) => {
        this.setState({
            page
        });
    };

    onCountPage = (countPages) => {
        this.setState({
            countPages
        });
    };

    renderLabel = (item) => {
        return (<React.Fragment>{item.name} <span className="text-muted">({item.birthYear})</span></React.Fragment>);
    };

    render(){
        const personList = <PersonListSwapi page={this.state.page}
                                            countPages={this.state.countPages}
                                            onPageTrigger={this.onPageTrigger}
                                            onItemSelected={this.onItemSelected}
                                            renderLabel={this.renderLabel} />;

        const personDetails = <PersonDetailsSwapi itemId={this.state.selectedItem}
                                                  detailLink={`/people/${this.state.selectedItem}`} />;

        const pagination = <Pagination onCountPage={this.onCountPage}
                                       onPageTrigger={this.onPageTrigger}
                                       getData={this.swapi.getCountPeople}
                                       activePage={this.state.page}
                                       url={`people`}/>;

        return (
            <React.Fragment>
                <Row25 left={personList} right={personDetails} />
                {pagination}
            </React.Fragment>
        );
    }
}
