import React from 'react';
import s from "./detail-loader.module.css";
import Spiner from "../../spiner/spiner";
import loader from './dart-logo.gif';

const DetailLoader = () => {
    return (
        <React.Fragment>
            <div className={s.imgbox}>
                <img className={s.vaider} src={loader} alt={'111'} />
            </div>
            <div className={s.desc}>
                <p className={s.header}>Получаем данные...</p>
                <Spiner/>
            </div>
        </React.Fragment>
    );
};

export default DetailLoader;