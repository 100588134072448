import React from 'react';
import ItemList from './../item-list';
import { WithSwapi } from './../../helpers/hoc-helpers';

const mapPersonProps = (swapi) => {
    return {
        getData: swapi.getAllPeople
    }
};
const PersonList = (props) => {
    return <ItemList {...props} />
};
const PersonListSwapi = WithSwapi(PersonList, mapPersonProps);

const mapStarshipsProps = (swapi) => {
    return {
        getData: swapi.getAllStarships
    }
};
const StarshipsList = (props) => {
    return <ItemList {...props} />
};
const StarshipsListSwapi = WithSwapi(StarshipsList, mapStarshipsProps);

const mapVehiclesProps = (swapi) => {
    return {
        getData: swapi.getAllVehicles
    }
};
const VehiclesList = (props) => {
    return <ItemList {...props} />
};
const VehiclesListSwapi = WithSwapi(VehiclesList, mapVehiclesProps);

const mapPlanetsProps = (swapi) => {
    return {
        getData: swapi.getPlanets
    }
};
const PlanetsList = (props) => {
    return <ItemList {...props} />
};
const PlanetsListSwapi = WithSwapi(PlanetsList, mapPlanetsProps);

const mapSpeciesProps = (swapi) => {
    return {
        getData: swapi.getAllSpecies
    }
};
const SpeciesList = (props) => {
    return <ItemList {...props} />
};
const SpeciesListSwapi = WithSwapi(SpeciesList, mapSpeciesProps);

export {
    PersonListSwapi,
    StarshipsListSwapi,
    VehiclesListSwapi,
    PlanetsListSwapi,
    SpeciesListSwapi
};