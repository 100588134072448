import React from 'react';
import s from './animated-side-buttons.module.css';

const AnimatedButtons = () => {
    return (
        <div className={s.buttons}>
            <p> <i className="fal fa-chevron-left"> </i> </p>
            <p> <i className="fal fa-chevron-right"> </i> </p>
        </div>
    );
};

export default AnimatedButtons;