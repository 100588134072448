import React,{Component} from 'react';
import s from './specie.module.css';
import BigPhoto from "./../../helpers/img-loader/img-loader";
import SwapiService from './../../../service/swapiService';
import Row11 from './../../helpers/row11';
import Spiner from "./../../spiner/spiner";
import EpisodesList from "./../../episode-list/episode-list";
import InfoBox from "../../helpers/standart-info-box/standart-info-box";
import StandartList from "../../standart-list/standart-list";

export default class Specie extends Component{

    swapi = new SwapiService();

    constructor(){
        super();

        this.state = {
            item:{},
            loading: true
        };

        this.onItemLoaded = (item) => {
            this.setState({
                item,
                loading: false,
            });
        };

        this.onItemError = () => {
            this.setState({
                loading: false
            });
        };
    }

    componentDidMount(){
        this.updateItem();
    }

    updateItem = () => {
        this.swapi.getSpecie(this.props.match.params.id)
            .then(this.onItemLoaded)
            .catch(() => {console.error('Ошибка catch')});
    };

    render () {
        const {loading, item} = this.state;
        const {getAllFilmsByUrls, getPeopleByUrls} = this.swapi;

        const view = loading ? '' : <OneObjView getPeopleByUrls={getPeopleByUrls}
                                                getAllFilmsByUrls={getAllFilmsByUrls}
                                                item={item}
        />;

        const load = loading ? <Spiner /> : '';

        return (
            <React.Fragment >
                {view}
                {load}
            </React.Fragment>
        );
    }
};



const OneObjView = ({item, getAllFilmsByUrls, getPeopleByUrls}) => {

    const {model, name, films, people, img} = item;

    const episodes = <EpisodesList getAllFilmsByUrls={getAllFilmsByUrls} films={films} />;
    const peopleCom = <StandartList titleBox={'Представители'} urlsArr={people} getDataByUrls={getPeopleByUrls} />;
    const rightContent = <React.Fragment>
                            {peopleCom}
                         </React.Fragment>;

    const viewDetails =  <ViewDetails item={item} />;

    const style_back = {
        backgroundImage: `url(${img})`
    };

    return (
        <React.Fragment>
            <div className={s.box}>
                <div className={s.back} style={style_back}> </div>
                <h1>{name}</h1>
                <p className={s.model}>{model}</p>
                <BigPhoto imgUrl={img} imgAlt={name}/>
            </div>
            <Row11 left={viewDetails} right={rightContent} />
            {episodes}
        </React.Fragment>
    );
};


const ViewDetails = ({item}) => {
    const {
        height, name, lifeSpan,
        classification, designation, eye_colors,
        hair_colors, skin_colors, language
    } = item;

    const title = `Сводка о ${name}`;
    const desc = `Товарищ Капитан! Вот всё, что нам удалось найти про эту рассу.`;
    const content = <div>
                        <p><span>Средний рост: </span> {height} см.</p>
                        <p><span>Средняя продолжительность жизни: </span> {lifeSpan} лет.</p>
                        <br />
                        <p><span>Классификация: </span> {classification}</p>
                        <p><span>Обозначение: </span> {designation}</p>
                        <p><span>Цвет(а) глаз: </span> {eye_colors}</p>
                        <p><span>Цвет(а) волос: </span> {hair_colors}</p>
                        <p><span>Цвет(а) кожи: </span> {skin_colors}</p>
                        <p><span>Язык: </span> {language}</p>
                    </div>;

    return (
        <InfoBox title={title} desc={desc} content={content} />
    );
};

