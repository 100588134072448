import React, {Component} from 'react';
import s from "./episode-list.module.css";
import Spiner from "./../spiner/spiner";
import InfoBox from "../helpers/standart-info-box/standart-info-box";
import {Link} from "react-router-dom";


export default class EpisodesList extends Component{

    constructor() {
        super();

        this.state = {
            loading: true,
            filmsList: null
        }
    }

    componentDidMount(){
        const {films, getAllFilmsByUrls} = this.props;
        getAllFilmsByUrls(films).then((arr) => {
            this.setState({
                loading: false,
                filmsList: arr
            });
        });
    }

    renderItems(arr) {
        return arr.map((el) => {
            const {title, img, episode_id, num} = el;
            const img_style = {
                backgroundImage : `url(${img})`
            };
            return (
                <div key={episode_id.toString()} className={s.epis_box}>
                    <Link to={`/films/${num}`}>
                        <div style={img_style} className={s.imgfilm}> </div>
                        <p>
                            {title}
                        </p>
                    </Link>
                </div>
            );
        });
    };

    render(){
        const items = !this.state.loading ? this.renderItems(this.state.filmsList) : <Spiner/>;
        return (
            <InfoBox title={`В эпизодах`} desc={``} content={items} />
        )
    }
};