import React from 'react';
import s from './row.module.css';

const Row11 = ({left, right}) => {
    return (
        <div className={s.inbox}>
            <div className={s.left}>{left}</div>
            <div className={s.right}>{right}</div>
        </div>
    );
};

export default Row11;