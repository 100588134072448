import React from "react";
import s from './footer.module.css';
import runlogo from './run-seo.png';

const Footer = () => {
    const date = new Date().getFullYear();
    return (
        <div className={s.footer}>
            <a className={s.link} target='_blank' rel="noopener noreferrer" title='Агенство интернет маркетинга Run SEO' href="http://run-seo.ru">
                <img className={s.runlogo} src={runlogo} alt='Интернет маркетинг' />
            </a>

            <p className={s.bottom}>
                production by <a title='Создание интернет проектов на React JS' target='_blank' rel="noopener noreferrer" href="http://run-seo.ru">Run SEO</a>
                <br />
                Star Wars и все связанные
                с ними данные
                защищены авторским правом Lucasfilm Ltd.
                <br />
                {date}
            </p>
        </div>
    );
};

export default Footer;