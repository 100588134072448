import React from 'react';
import s from "./standart-info-box.module.css";

const InfoBox = ({title, desc, content}) => {
    const descHtml = desc ? <h3>{desc}</h3> : ``;
    return (
        <div className={s.desc}>
            <h2>{title}</h2>
            {descHtml}
            {content}
        </div>
    );
};

export default InfoBox;