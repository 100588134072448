import React from 'react';
import s from './app.module.css';
import Header from "./../header/";
//import RandomPlanet from "./../random-planet/";
import SwapiService from "./../../service/swapiService";
import {SwapiProvider} from "./../../service/swapi-service-context";
//Импортируется BrowserRouter, а при использовании мы пишем Router, так удобнее
import {BrowserRouter as Router, Route}  from "react-router-dom";
import MyParticles from './../particles';
import VehiclesPage from "../vehicles-page/vehicles-page";
import Vehicle from "../vehicles-page/vehicle/vehicle";
import PersonPage from "./../person-page";
import Person from "../person-page/person/person";
import StarshipsPage from "./../starships-page";
import Starship from "./../starships-page/starship";
import PlanetsPage from "../planets-page/planets-page";
import Planet from "../planets-page/planet/planet";
import SpeciesPage from "../species-page/species-page";
import Specie from "../species-page/specie/specie";
import FilmsPage from "../films-page/films-page";
import Films from "../films-page/films/films";
import Modal from "../modal/modal";
import Footer from "../footer/footer";
import IndexPage from "../index-page/index-page";


export default class App extends React.Component {

    swapi = new SwapiService();

    render (){

        return (
            <SwapiProvider value={this.swapi}>
                <Router>
                    <div className={s.top_layout}>
                        <div id="back_layout" className={s.back_layout}>
                            <MyParticles/>
                        </div>
                        <Header />
                        <div className={`container`}>
                            <Route path="/" exact component={IndexPage} />
                            <Route path="/people" exact component={PersonPage} />
                            <Route path="/people/:id" component={Person} />

                            <Route path="/starships" exact component={StarshipsPage} />
                            <Route path="/starships/:id" component={Starship} />

                            <Route path="/vehicles" exact component={VehiclesPage} />
                            <Route path="/vehicles/:id" component={Vehicle} />

                            <Route path="/planets" exact component={PlanetsPage} />
                            <Route path="/planets/:id" component={Planet} />

                            <Route path="/species" exact component={SpeciesPage} />
                            <Route path="/species/:id" component={Specie} />

                            <Route path="/films" exact component={FilmsPage} />
                            <Route path="/films/:id" component={Films} />

                            {/*<RandomPlanet />*/}
                        </div>
                    </div>

                    <Footer />

                    <Modal />

                </Router>
            </SwapiProvider>
        );
    }
}
