import React,{Component} from 'react';
import s from './vehicles.module.css';
import BigPhoto from "./../../helpers/img-loader/img-loader";
import SwapiService from './../../../service/swapiService';
import Row11 from './../../helpers/row11';
import Spiner from "./../../spiner/spiner";
import EpisodesList from "./../../episode-list/episode-list";
import InfoBox from "../../helpers/standart-info-box/standart-info-box";
import StandartList from "../../standart-list/standart-list";

export default class Vehicle extends Component{

    swapi = new SwapiService();

    constructor(){
        super();

        this.state = {
            item:{},
            loading: true
        };

        this.onItemLoaded = (item) => {
            this.setState({
                item,
                loading: false,
            });
        };

        this.onItemError = () => {
            this.setState({
                loading: false
            });
        };
    }

    componentDidMount(){
        this.updateItem();
    }

    updateItem = () => {
        this.swapi.getVehicle(this.props.match.params.id)
            .then(this.onItemLoaded)
            .catch(() => {console.error('Ошибка catch')});
    };

    render () {
        const {loading, item} = this.state;
        const {getAllFilmsByUrls, getPeopleByUrls} = this.swapi;

        const view = loading ? '' : <OneObjView getPeopleByUrls={getPeopleByUrls}
                                                getAllFilmsByUrls={getAllFilmsByUrls}
                                                item={item}
        />;

        const load = loading ? <Spiner /> : '';

        return (
            <React.Fragment >
                {view}
                {load}
            </React.Fragment>
        );
    }
};



const OneObjView = ({item, getAllFilmsByUrls, getPeopleByUrls}) => {

    const {model, name, films, pilots, img} = item;

    const episodes = <EpisodesList getAllFilmsByUrls={getAllFilmsByUrls} films={films} />;
    const pilotsCom = <StandartList titleBox={'Могут управлять'} urlsArr={pilots} getDataByUrls={getPeopleByUrls} />;
    const rightContent = <React.Fragment>
                            {pilotsCom}
                         </React.Fragment>;

    const viewDetails =  <ViewDetails item={item} />;

    const style_back = {
        backgroundImage: `url(${img})`
    };

    return (
        <React.Fragment>
            <div className={s.box}>
                <div className={s.back} style={style_back}> </div>
                <h1>{name}</h1>
                <p className={s.model}>{model}</p>
                <BigPhoto imgUrl={img} imgAlt={name}/>
            </div>
            <Row11 left={viewDetails} right={rightContent} />
            {episodes}
        </React.Fragment>
    );
};


const ViewDetails = ({item}) => {
    const {
        cargoCapacity,
        costInCredits,
        crew, length, manufacturer,
        maxSpeedAtmo, model, name
    } = item;

    const title = `Сводка о ${name}`;
    const desc = `Товарищ Капитан! Вот всё, что нам удалось найти по вашему запросу.`;
    const content = <div>
                        <p><span>Производитель: </span> {manufacturer}</p>
                        <p><span>Модель: </span> {model}</p>
                        <br />
                        <p><span>Стоимость в гал. кредитах: </span> {costInCredits}</p>
                        <p><span>Грузоподъемность (кг.): </span> {cargoCapacity}</p>
                        <p><span>Экипаж (чел.) </span> {crew}</p>
                        <p><span>Макс. скорость в атмосфере (км.ч.): </span> {maxSpeedAtmo}</p>
                        <p><span>Длина (метры): </span> {length}</p>
                    </div>;

    return (
        <InfoBox title={title} desc={desc} content={content} />
    );
};

